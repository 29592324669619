<template>
  <header
    class="fixed left-0 right-0 h-20 border-b border-surface-60 bg-surface-10 grid items-center content-center px-5 lg:px-0 grid-cols-3 lg:grid-cols-[224px,1fr]"
  >
    <div class="grid w-fit lg:hidden" @click="onOpenNavbarMenu">
      <span class="icon-menu text-2xl cursor-pointer" />
    </div>
    <div class="grid h-20">
      <h1 class="flex justify-center self-center">
        <Img
          :src="`${cdn}/heltia-logo.webp`"
          alt="Heltia Logo"
          width="128"
          @click="$router.push('/')"
          class="cursor-pointer"
        />
      </h1>
    </div>
    <WebExpHeader :actionAreaVisible="true" />
  </header>
  <main id="app" class="min-h-screen w-full bg-surface-base flex flex-col">
    <div>
      <WebExpNavbar />
    </div>
    <div class="overflow-auto lg:ml-[224px] pt-20 lg:pt-0">
      <ModalAppDownload
        :open="isAppDownloadModalOpen"
        :onAction="closeAppDownloadModal"
      />
      <ModalAvailableForMatching
        :open="isAvailableForMatchingModalOpen"
        :onClose="closeChangeAvailableForMatchingModal"
      />
      <slot />
    </div>
  </main>
</template>
<script setup lang="ts">
import { useUiStore } from "~/store/ui";
const config = useRuntimeConfig();
const cdn = config.public.cdn;

const uiStore = useUiStore();
const isAppDownloadModalOpen = computed(() => uiStore.appDownloadOpen);
const isAvailableForMatchingModalOpen = computed(
  () => uiStore.changeAvailabilityModalOpen
);

const onOpenNavbarMenu = () => {
  uiStore.openNavbarMenu();
};

const closeAppDownloadModal = () => {
  uiStore.closeAppDownload();
};

const closeChangeAvailableForMatchingModal = () => {
  uiStore.closeChangeAvailabilityModal();
};
</script>
