<template>
  <Confirmation
    title="Çıkış Yap"
    :text="
      authStore?.user?.email +
      ' mail adresiyle çıkış yapmak istediğinize emin misiniz?'
    "
    confirmActionText="Çıkış yapın"
    closeActionText="Vazgeçin"
    :onCancel="onClose"
    :onConfirm="handleLogoutConfirm"
    :isPopUp="true"
    :alert="true"
    :open="open"
  />
</template>
<script setup lang="ts">
import { useAuthStore } from "~/store/auth";
import { useUserStore } from "~/store/user";
import { onBeforeRouteLeave } from "vue-router";
import Tracking from "~/utils/tracking";
import { useQuestionnaireStore } from "~/store/questionnaire";
import { useActivationStore } from "~/store/activation";
import { useCheckoutStore } from "~/store/checkout";
import { useMatchingStore } from "~/store/matching";
import { useVerticalStore } from "~/store/vertical-selection";
import { useUiStore } from "~/store/ui";
import { useInvoiceStore } from "~/store/invoice";
import { useMeetingPackageOptionStore } from "~/store/meetingPackageOption";
import { logOut } from "~/helpers/api/auth";
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const authStore = useAuthStore();
const userStore = useUserStore();
const questionnaireStore = useQuestionnaireStore();
const activationStore = useActivationStore();
const checkoutStore = useCheckoutStore();
const matchingStore = useMatchingStore();
const verticalStore = useVerticalStore();
const uiStore = useUiStore();
const invoiceStore = useInvoiceStore();
const meetingPackageOptionStore = useMeetingPackageOptionStore();
const tracking = Tracking.getInstance();

const handleLogoutConfirm = async () => {
  await authStore.logoutApiCall();

  await authStore.logout();
  await userStore.logout();
  await questionnaireStore.clearQuestionnaireStore();

  activationStore.$reset();
  checkoutStore.$reset();
  matchingStore.$reset();
  verticalStore.$reset();
  uiStore.$reset();
  invoiceStore.$reset();
  meetingPackageOptionStore.$reset();

  tracking.loggedOut();
  props.onClose();
  navigateTo(routes.signin.path);
};

onBeforeRouteLeave((to, from, next) => {
  if (!props.open) {
    next();
  } else {
    next(false);
  }
});
</script>
