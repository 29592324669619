<template>
  <button
    class="nav-item"
    :class="{
      'nav-item--selected': isSelected,
    }"
    @click="handleClick"
  >
    <div class="flex gap-2 items-center">
      <span v-if="!!icon" class="text-2xl" :class="icon" />
      <span class="l2 text-left">{{ name }}</span>
      <Badge
        v-if="isMessagesNavItem && messagingStore.unreadSessionCount > 0"
        :text="messagingStore.unreadSessionCount.toString()"
      />
    </div>
  </button>
</template>
<script setup lang="ts">
import { useMessagingStore } from "~/store/messaging";
import { useRouter } from "vue-router";
import Tracking from "~/utils/tracking";

const props = defineProps({
  navigation: {
    type: Object,
    required: true,
  },
  onClick: {
    type: Function,
    required: true,
  },
});
const { icon, name, path, key } = props.navigation;

const messagingStore = useMessagingStore();

const route = useRoute();
const router = useRouter();
const isSelected = computed(() => {
  let actualItemPath = path;
  if (actualItemPath === undefined) {
    return false;
  }
  if (actualItemPath.endsWith("/")) {
    actualItemPath = actualItemPath.slice(0, -1);
  }

  return route.path.includes(actualItemPath);
});
const isMessagesNavItem = computed(
  () => props.navigation.name === "Mesajlarım"
);

const tracking = Tracking.getInstance();

onMounted(() => {
  if (isMessagesNavItem.value) {
    messagingStore.initiate();
  }
});

const handleClick = () => {
  tracking.navBarClicked({
    Page: name,
  });

  if (path !== undefined) {
    router.push(path);
  }
  props.onClick(key);
};
</script>
<style scoped>
.nav-item {
  @apply px-2 py-1 lg:p-4 rounded-xl bg-surface-10 cursor-pointer w-[192px];
}

.nav-item:hover {
  @apply bg-neutral-10;
}

.nav-item:disabled {
  @apply cursor-not-allowed;
}

.nav-item--selected {
  @apply bg-primary-10;
}

[class*="icon-"]:before {
  @apply !inline mr-0;
}
</style>
