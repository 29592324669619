import { UserRole } from "~/model/user";

export type Navigation = {
  name: string;
  key?: string;
  path?: string;
  icon: string;
  position: "top" | "bottom";
  userRoles: UserRole[];
  count?: number;
  onClick?: {
    type: Function;
  };
  visible: boolean;
};

export const getNavigations = (userRole: UserRole) => {
  const navigations: Navigation[] = [
    {
      icon: "icon-home",
      name: userRole === UserRole.Client ? "Yolculuğum" : "Anasayfam",
      path: routes.journey.path,
      position: "top",
      userRoles: routes.journey.userRoles,
      visible: true,
    },
    {
      icon: "icon-message",
      name: "Mesajlarım",
      path: routes.messages.path,
      position: "top",
      userRoles: routes.messages.userRoles,
      visible: true,
    },
    {
      icon: "icon-calendar",
      name: "Faturalar",
      path: routes.invoices.path,
      position: "top",
      userRoles: routes.invoices.userRoles,
      visible: true,
    },
    {
      icon: "icon-provider",
      name: "Danışman ekibim",
      path: routes.myTeam.path,
      position: "top",
      userRoles: routes.myTeam.userRoles,
      visible: false,
    },
    {
      icon: "icon-calendar",
      name: "Randevu geçmişim",
      path: routes.pastAppointments.path,
      position: "top",
      userRoles: routes.pastAppointments.userRoles,
      visible: false,
    },
    {
      icon: "icon-clock",
      name: "Uygun saatlerim",
      path: routes.availableTime.path,
      position: "top",
      userRoles: routes.availableTime.userRoles,
      visible: false,
    },
    {
      icon: "icon-user",
      name: "Profilim",
      path: routes.profile.path,
      position: "top",
      userRoles: routes.profile.userRoles,
      visible: true,
    },
    {
      icon: "icon-settings",
      name: "Ayarlarım",
      path: routes.settings.path,
      position: "bottom",
      userRoles: routes.settings.userRoles,
      visible: false,
    },
    {
      icon: "icon-logout",
      name: "Güvenli Çıkış",
      key: "logout",
      position: "bottom",
      userRoles: [UserRole.Client, UserRole.Provider],
      visible: true,
    },
  ];
  return navigations.filter(
    (nav) => nav.visible && nav.userRoles.includes(userRole)
  );
};

export { UserRole };
