<template>
  <div
    :class="{
      overlay: open,
    }"
    @click="onAction"
  >
    <div v-if="open" class="popup" @click.stop>
      <span class="s1 m lg:d text-center w-full">{{ title }}</span>
      <div class="grid gap-1">
        <div class="flex items-center justify-center">
          <Img
            src="/images/heltia-qr.png"
            alt="Heltia Download Qr Code"
            class="w-[180px] p-4 my-4 object-cover rounded-lg bg-white-500 border border-neutral-200"
            loading="eager"
          />
        </div>
        <span class="b2 text-center w-full text-neutral-40">{{ message }}</span>
      </div>
      <div class="content">
        <div class="footer">
          <ButtonSecondaryMedium :label="this.buttonText" @click="onAction" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    onAction: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "Bu özellik için mobil uygulamamızı kullanabilirsiniz.",
    },
    message: {
      type: String,
      required: false,
      default: "QR kodu okutarak uygulamaya devam edebilirsiniz",
    },
    buttonText: {
      type: String,
      required: false,
      default: "Geri dönün",
    },
  },
};
</script>

<style scoped>
.overlay {
  @apply fixed inset-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-40;
}

.popup {
  @apply bg-surface-10 px-10 py-8 rounded-xl grid gap-6 w-[374px] z-50;
}

.content {
  @apply text-center;
}

.footer {
  @apply flex justify-center items-center flex-col gap-4;
}
</style>
