<template>
  <ModalGeneric v-if="open">
    <template v-slot:message>
      <span class="b1">
        Müsaitlik durumunuzu kapattığınız takdirde yeni danışanlarla
        eşleştirilmezsiniz.
      </span>
    </template>
    <template v-slot:footer>
      <div class="flex gap-2">
        <ButtonSecondaryMedium label="Vazgeçin" @click="handleCancel" />
        <ButtonPrimaryMedium label="Kaydedin" @click="handleSave" />
      </div>
    </template>
  </ModalGeneric>
</template>

<script setup lang="ts">
import { useUserStore } from "~/store/user";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const userStore = useUserStore();

const handleCancel = () => {
  props.onClose();
};

const handleSave = async () => {
  await userStore.updateAvailableForMatching();
  props.onClose();
};
</script>
