<template>
  <transition name="fade">
    <div
      :class="{
        overlay: navbarMenuOpen,
      }"
      class="lg:pt-20"
      @click="onCloseNavbarMenu"
    >
      <transition name="slide">
        <div v-if="isNavbarVisible" class="navbar slide">
          <div class="grid gap-3 p-4">
            <span
              class="icon-remove text-2xl lg:hidden"
              @click="onCloseNavbarMenu"
            />
            <WebExpNavItem
              v-for="nav in topNavigations"
              :key="nav.path"
              :navigation="nav"
              :onClick="onNavItemClick"
            />
          </div>
          <div
            :class="{
              'mb-20': isMobileSafari,
            }"
            class="grid gap-3 p-4 border-t border-surface-60 h-fit self-end"
          >
            <WebExpNavItem
              v-for="nav in bottomNavigations"
              :key="nav.path"
              :navigation="nav"
              :onClick="onNavItemClick"
            />
          </div>
        </div>
      </transition>
    </div>
  </transition>
  <router-view>
    <WebExpNavbarLogout
      :open="showLogoutConfirmation"
      :onClose="handleLogoutCancel"
    />
  </router-view>
</template>

<script setup lang="ts">
import { getNavigations } from "../navItem/navigations";
import { useAuthStore } from "~/store/auth";
import { useUiStore } from "~/store/ui";

const showLogoutConfirmation = ref(false);
const authStore = useAuthStore();
const uiStore = useUiStore();

const navbarMenuOpen = computed(() => uiStore.navbarMenuOpen);

const onCloseNavbarMenu = () => {
  uiStore.closeNavbarMenu();
};

const handleLogoutClicked = () => {
  showLogoutConfirmation.value = true;
};

const handleLogoutCancel = () => {
  showLogoutConfirmation.value = false;
};

const userRole = computed(() => authStore.getUserRole);

const navigations = computed(() => getNavigations(userRole.value));
const topNavigations = computed(() =>
  navigations.value.filter((nav) => nav.position === "top")
);
const bottomNavigations = computed(() =>
  navigations.value.filter((nav) => nav.position === "bottom")
);

const onNavItemClick = (key: String) => {
  if (key === "logout") {
    handleLogoutClicked();
  }
  onCloseNavbarMenu();
};

const isNavbarVisible = ref(navbarMenuOpen.value || window?.innerWidth >= 1024);

watch(navbarMenuOpen, (newVal) => {
  isNavbarVisible.value = newVal || window.innerWidth >= 1024;
});

const resizeHandler = () => {
  isNavbarVisible.value = navbarMenuOpen.value || window.innerWidth >= 1024;
};

const { isMobileSafari } = useBrowserDetect();

watch(() => navbarMenuOpen, resizeHandler);

onMounted(() => {
  window.addEventListener("resize", resizeHandler);
});

onUnmounted(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>

<style scoped>
.overlay {
  @apply fixed inset-0 bg-black bg-opacity-50 lg:hidden;
}

.navbar {
  @apply bg-surface-10 overflow-y-auto fixed grid grid-rows-[auto,1fr,auto] border-r border-surface-60 h-screen lg:h-[calc(100vh-80px)];
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
